'use strict';
var $ = require('jquery');

function init(){
	console.log('campaignBanner.js -> init()', window.app.cookies);

	function setCookie(name, value, expires, path, domain, secure) {
		console.log('setCookie(%s, %s, %s, %s, %s, %s)', name, value, expires, path, domain, secure);

		var cookieText = encodeURIComponent(name) + '=' + encodeURIComponent(value);

		if (expires) {
			var date = new Date();
			date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
			cookieText += '; expires=' + date.toUTCString();
		}

		if (path) {
			cookieText += '; path=' + path;
		}

		if (domain) {
			cookieText += '; domain=' + domain;
		}

		if (secure) {
			cookieText += '; secure';
		}

		// console.log('setCookie() -> cookieText=%s', cookieText);
		document.cookie = cookieText;
	}

	/**
	 * getCookieValue by name
	 * @param {string} name The cookie name
	 * @returns {string} The cookie value
	 */
	function getCookieValue(name) {
		var cookieName = encodeURIComponent(name) + '=';
		var cookieStart = document.cookie.indexOf(cookieName);
		var cookieValue = null;
		var cookieEnd;

		if (cookieStart > -1) {
			cookieEnd = document.cookie.indexOf(';', cookieStart);

			if (cookieEnd == -1) {
				cookieEnd = document.cookie.length;
			}

			cookieValue = decodeURIComponent(document.cookie.substring(cookieStart + cookieName.length, cookieEnd));
		}

		return cookieValue;
	}

	var modal = $('.js-Modal');
	var content = $('.js-Modal__Content');
	var primaryCta = $('.js-primaryCta');
	var secondaryCTA = $('.js-secondaryCTA ');
	var closeBtn = $(".Modal__CloseBtn");
	var container = $(".Modal");
	var bg = $(container.find(".Modal__Background"));
	var isCampaignBanner = modal.is("[data-campaign-banner]");

	console.log('campaignBanner init() -> getCookieValue: %s, isCampaignBanner: %o', getCookieValue(window.app.cookies.campaignBanner.name), isCampaignBanner);
	if (!getCookieValue(window.app.cookies.campaignBanner.name) && isCampaignBanner) {
		window.app.cookies.campaignBanner.value = getCookieValue(window.app.cookies.campaignBanner.name);
		modal.attr('data-auto-open', true);

		window.addEventListener('app.modal.onClose', function(event) {
			// console.log('EventListener(event) -> event: %s', event);
			setCookie(window.app.cookies.campaignBanner.name, true, window.app.cookies.campaignBanner.expires, window.app.cookies.campaignBanner.path);
		});

		/* update values on click or short touch */
		primaryCta.on('click', function(e) {
			setCookie(window.app.cookies.campaignBanner.name, true, window.app.cookies.campaignBanner.expires, window.app.cookies.campaignBanner.path);
		});

		bg.click(function() {
			setCookie(window.app.cookies.campaignBanner.name, true, window.app.cookies.campaignBanner.expires, window.app.cookies.campaignBanner.path);
		});

		closeBtn.click(function() {
			setCookie(window.app.cookies.campaignBanner.name, true, window.app.cookies.campaignBanner.expires, window.app.cookies.campaignBanner.path);
		});
	}
}

module.exports = {
	init: init
};

