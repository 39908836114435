'use strict';
var $ = require('jquery');

function init(){

	if ($('body').prop('id') == 'mwm') {
		var urlParams = window.location.search;
		var test = urlParams.substring(1);
		var dec = null;

		console.log('antrag');

		// check if string is decodable
		try {
			window.atob(test);
			dec = window.atob(test);
		} catch(e) {
			$('.PageIntro__Subline').hide();
			$('.Text.Text--pLarge ').html('Leider ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihren persönlichen Einladungslink und versuchen es erneut.');
			$('.ContentModule').hide();
		}

		// check if json
		var obj = null;
		try {
			obj = $.parseJSON(dec);
		} catch(e) {
			$('.PageIntro__Subline').hide();
			$('.Text.Text--pLarge ').html('Leider ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihren persönlichen Einladungslink und versuchen es erneut.');
			$('.ContentModule').hide();
		}

		if(obj){
			var introducerName = obj.params[0].introducerName;
			var recommendBonus = obj.params[0].recommendBonus;
			if(introducerName && recommendBonus) {

				$('.introducer').html(introducerName);
				switch(recommendBonus) {
					case 'Empfehler':
						$('#bonus').html('');
						$('#bonus').parent().addClass('hidden');
						$('.Table').addClass('hidden');
						break;
					case 'geteilt':
						$('#bonus').html('Die Prämie wird 50/50 zwischen Ihnen und ' + introducerName + ' aufgeteilt.');
						break;
					case 'Empfohlener':
						$('#bonus').html('Die Prämie wird zu ihren Gunsten ausgezahlt.');
						break;
					default:
						$('#bonus').html('');
				}

				$('#bonusToggle').on('click', function(){
					$('#bonusTable').removeClass('hidden');
				});
			} else {
				$('.PageIntro__Subline').hide();
				$('.Text.Text--pLarge ').html('Leider ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihren persönlichen Einladungslink und versuchen es erneut.');
				$('.ContentModule').hide();
			}
		}
	}
}

module.exports = {
	init: init
};

