"use strict";

var conf = require("./conf");
var $ = require("jquery");
window.jQuery = $;

window.screenSm = 544;
window.screenMd = 960;
window.screenLg = 1200;

var cookieConsent = require("./cookieConsent/index.js");

require("./libs/smoothscroll.js").polyfill();

require("./libs/perfect-scrollbar.js");
require("./libs/owl.carousel.js");

require("./jQueryPlugins/mainNav.js");
require("./jQueryPlugins/accordion.js");
require("./jQueryPlugins/loadMore.js");
require("./jQueryPlugins/rateForm.js");
require("./jQueryPlugins/modal.js");

require("./jQueryPlugins/map.js");

require("./jQueryPlugins/jquery.sticky-kit.js");
require("./jQueryPlugins/chart.js");

require("./markerClusterer.js");
require("./infobox.js");

var mwm = require('mwm');
var co2SavingsCalculator = require('co2SavingsCalculator');
var campaignBanner = require('campaignBanner');

//-----------------------------------------

function initNav() {
	$(".MainNav").mainNav();
}

//-----------------------------------------
document.addEventListener("wheel", function(event){
    if(document.activeElement.type === "number"){
        document.activeElement.blur();
    }
});

function init() {
	console.log("Initializing with", conf);
	console.log("Having jQuery version", $().jquery);


	new cookieConsent();

	initNav();

	mwm.init();

	co2SavingsCalculator.init();
	campaignBanner.init();

	// STICKY SIDEBAR
	$(".js-Sticky").stick_in_parent({offset_top: 100});

	// LANGUAGE SWITCH
	$(".LanguageSwitch").on("change", function () {
		var url = $(this).val(); // get selected value
		if (url) {
			// require a URL
			window.location = url; // redirect
		}
		return false;
	});

    //CANCEL CONTRACT FORM
    function ajaxSubmit($form, e){
        e.preventDefault();
        $('#loading-indicator').show();
        $.ajax({
            type:'POST',
            url: $form.attr('action'),
            data: $form.serialize(),
            cache: false
        }).done(function(data){
            $('#loading-indicator').hide();
            if (data.success){

                // Hide the form
                $('#cancelContract').hide();

                // Show success "page"
                $('#cancelContractSuccess').show();
                document.getElementById('cancelContractSuccess').scrollIntoView();

                // List Client Data
                $.each($form.serializeArray(),function(index,value){
                    const id = '#confirmation-' + value.name.slice(value.name.indexOf('[') + 1, value.name.indexOf(']'));
                    if ($(id)) {
                        if (value.value != '') {
                            $(id).show();
                            var value = value.value;

                            if(value == 'schnellstmoeglich') value = 'Kündigung zum nächstmöglichen Termin';
                            if(value == 'ausserordentlich') value = 'Außerordentliche Kündigung';
                            if(value == 'wunschdatum') value = 'Kündigung zum festgelegten Datum';
                            if(value == 'umzug') value = 'Kündigung wegen Umzug';


                            $(id).find('span').text(value)
                        }
                    }
                })

                // Send the data to the Prokon API to get a PDF
                $.ajax({
                    type:'POST',
                    url: '/actions/businessLogic/getCancelContractPdf',
                    data: $form.serialize(),
                    cache: false
                }).done(function(data){

                    // Populate PDF Download form with the received PDF name
                    $('#cancelContractPdfName').val(data.pdfName)

                    // Show the download button/form
                    $('#cancelContractDownloadPdf').show()
                });
            }
            else {
                if (data.errors){
                    $.each(data.errors, function(field, msgs){
                        $.each(msgs, function(index, msg){
                            $('<div>').text(msg).appendTo('.error-messages')
                        })
                    });
                }
            }
        });

    }

    $('#cancelContract').on('submit',function(e){
        e.preventDefault();
        ajaxSubmit($('#cancelContract'),e);
    })


	// TEASER OWL CAROUSEL
	$(".owl-carousel").owlCarousel({
		loop: false,
		responsiveClass: true,
		stagePadding: 50,
		nav: false,
		responsive: {
			0: {
				items: 1
			},
			544: {
				items: 2
			}
		}
	});

	// LOAD MORE FUNCTIONALITY
	$(".js-LoadMore").loadMore();

	// MODALS
	$(".js-Modal").modal();

	// MAP
	window.addEventListener('app.cookieConsent.onChange', function(event) {
  		if (app.cookies.cookieConsent.types.external) {
			$(".js-Map").googleMap();
		}
		if (app.cookies.cookieConsent.types.marketing) {
			//console.log('change cookie set - consent_granted ', app.cookies.cookieConsent.types.marketing);
			dataLayer.push({'event': 'consent_granted'});
		} else {
			//console.log('change cookie set - consent_denied ', app.cookies.cookieConsent.types.marketing);
			dataLayer.push({'event': 'consent_denied'});
		}

	});

	// INIT ACCORDIONS
	$(".js-Accordion").accordion();

	// INIT RATEFORM
	$(".js-RateForm").rateForm();

	// INIT TABLE
	$(".Table__Table").perfectScrollbar();

	// INIT CHARTS
	$(".Chart").chart();

	// INIT GA-OPT-OUT LINK
	$(".js-GoogleAnalytics__OptOut").on("click", function (e) {
		e.preventDefault();
		e.stopPropagation();

		// function defined in gtm
		if (typeof window.gaOptout === "function") {
			window.gaOptout();
		}

		return false;
	});
}

var App = {
	init: init,
	initReactApp: function() {
		// React observer
		if (document.getElementById("ElectricityAndMembershipApplication") ||
			document.getElementById("ElectricityApplicationDiscount") ||
			document.getElementById("ElectricityApplication") ||
			document.getElementById("MembershipApplication") ||
			document.getElementById("RecommendMembershipApplication") ||
			document.getElementById("MembershipIncreseApplication") ||
			document.getElementById("ChangeAddressForm") ||
			document.getElementById("ReadElectricMeterForm") ||
			document.getElementById("ChangeContactDataForm") ||
			document.getElementById("RecommendMember") ||
			document.getElementById("NewsletterOrderForm") ||
			document.getElementById("GiveawayMembership")) {


			var s = document.createElement("script");
			// Add the bundled react application script only if it's neccesary
			s.setAttribute("src", "/js/react-app.min.js");
			// Add backup Bundle form Prod (11/03/24)
			//s.setAttribute("src", "/js/react-app.prodbackup.js");
			document.body.appendChild(s);
		}
	}
};

$(function () {
	App.init();
	App.initReactApp();
});
