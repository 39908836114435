'use strict';
var $ = require('jquery');

function init(){

	// console.log('co2SavingsCalculator.js -> init()');

	var form = $('.js-Co2SavingsCalculator__Form');

	var kwhInput = $('.js-Co2SavingsCalculator__KwhInput');
	var kwhSelectBtns = $('.js-Co2SavingsCalculator__KwhBtn');
	// console.log('co2SavingsCalculator.js -> init(): kwhSelectBtns=', kwhSelectBtns);

	var horizon = $('.js-Co2SavingsCalculator__Horizon');
	var step1 = $('.js-Co2SavingsCalculator__Step1');
	var step2 = $('.js-Co2SavingsCalculator__Step2');
	var stepperBtns = $('.js-Co2SavingsCalculator__StepperBtn');
	var sharesInput = $('.js-Co2SavingsCalculator__Shares');
	var euroInput = $('.js-Co2SavingsCalculator__Euro');
	var euroPerShare = 50;
	var tonsPerShare = 0.2;
	var tonsPerKwh = 0.00035;

	var tonsTotalOutput = $('.js-Co2SavingsCalculator__TonsTotal');

	var sharesCalculation = $('.js-Co2SavingsCalculator_SharesCalculation');
	var sharesExplanation = $('.js-Co2SavingsCalculator_SharesExplanation');
	var sharesOutput = $('.js-Co2SavingsCalculator_SharesOutput');
	var savingsBySharesOutput = $('.js-Co2SavingsCalculator_SavingsBySharesOutput');
	// var sharesCtaChange = $('.js-Co2SavingsCalculator_CtaSharesChange');
	var sharesCtaExplanation = $('.js-Co2SavingsCalculator_CtaSharesExplanation');
	var sharesCtaBack = $('.js-Co2SavingsCalculator_CtaSharesBack');

	var kwhCalculation = $('.js-Co2SavingsCalculator_KwhCalculation');
	var kwhExplanation = $('.js-Co2SavingsCalculator_KwhExplanation');
	var kwhOutput = $('.js-Co2SavingsCalculator_KwhOutput');
	var savingsByKwhOutput = $('.js-Co2SavingsCalculator_SavingsByKwhOutput');
	// var kwhCtaChange = $('.js-Co2SavingsCalculator_CtaKwhChange');
	var kwhCtaExplanation = $('.js-Co2SavingsCalculator_CtaKwhExplanation');
	var kwhCtaBack = $('.js-Co2SavingsCalculator_CtaKwhBack');

	var savingsByBicycleOutput = $('.js-Co2SavingsCalculator_SavingByBicycleOutput');
	var savingsYearsBicycleOutput = $('.js-Co2SavingsCalculator_SavingYearsBicycleOutput');

	var submitBtn = $('.js-Co2SavingsCalculator__Submit');
	var resetBtn = $('.js-Co2SavingsCalculator__Reset');

	var rotor = $('.js-Co2SavingsCalculator__Horizon--Rotor');
	var trees = $('.js-Co2SavingsCalculator__Horizon--Trees');
	var tree1 = $('.js-Co2SavingsCalculator__Tree--1');
	var tree2 = $('.js-Co2SavingsCalculator__Tree--2');
	var tree3 = $('.js-Co2SavingsCalculator__Tree--3');
	var tree4 = $('.js-Co2SavingsCalculator__Tree--4');
	var tree5 = $('.js-Co2SavingsCalculator__Tree--5');

	// Animation modifiers
	var duration = 'slow'; // default=400, fast=200, slow=600
	var easingFunction = 'linear';

	var intervalId = 0;

	var isMobile = window.matchMedia("(max-width: 543px)").matches;

	function calculateSavings(shares, euros, kwh) {
		// console.log('calculateSavings(%s, %s, %s)', shares, euros, kwh);

		if (!isMobile) {
			scrollTo({
				top: horizon.offset().top,
				behavior: 'smooth'
			});
		}

		/**
		 * Ergebnis Mitgliedschaft
		 **/
		var sharesString = shares + ' Anteile';
		if (shares > 0) {
			sharesString += ', ' + euros + '€:';
			// sharesCtaChange.attr('hidden', '');
		}/* else {
			sharesCtaChange.removeAttr('hidden');
		}*/
		sharesOutput.html(sharesString);

		var savingsByShares = (shares * (tonsPerShare * 10)) / 10;
		savingsBySharesOutput.html(savingsByShares.toString().replace('.', ',') + ' Tonnen');


		/**
		 * Ergebnis Stromkunde
		 **/
		kwhOutput.html(kwh + ' kWh/Jahr:');
		/*if (kwh <= 0) {
			kwhCtaChange.removeAttr('hidden');
		} else {
			kwhCtaChange.attr('hidden', '');
		}*/

		var savingsByKwh = (kwh * (tonsPerKwh * 100000)) / 100000;
		savingsByKwhOutput.html(savingsByKwh.toString().replace('.', ',') + ' Tonnen');

		// console.log('savingsByShares: ', savingsByShares, 'savingsByKwh: ', savingsByKwh);
		var tonsTotal = (parseFloat(savingsByShares) + parseFloat(savingsByKwh)).toFixed(5);
		// console.log('tonsTotal: ', tonsTotal);
		tonsTotalOutput.html(parseFloat(tonsTotal).toString().replace('.', ','));

		/**
		 * Ergebnis Vergleich Fahrrad
		 **/
		var durationCycling = Math.ceil((tonsTotal / 4));

		var yearWord = durationCycling === 1 ? ' Jahr' : ' Jahre';
		savingsYearsBicycleOutput.html(durationCycling + yearWord);

		var savingsByBicycle = durationCycling * 3;
		savingsByBicycleOutput.html(savingsByBicycle);
	}

	function submitForm() {
		if (isMobile) {
			scrollTo({
				top: step1.offset().top,
				behavior: 'smooth'
			});
			step1.fadeOut(duration, easingFunction, function() {
				// console.log('mobile animation: step1 fadeOut end');
				horizon.fadeIn(duration, easingFunction, function() {
					// console.log('mobile animation: Horizon fadeIn end');
					horizon.delay(1400).fadeOut(duration, easingFunction, function() {
						// console.log('mobile animation: Horizon fadeOut end');
						step2.fadeIn(duration, easingFunction, function() {
							// console.log('mobile animation: step3 fadeIn end');
						});
					});
				});
			});
		}

		calculateSavings(sharesInput.val(), euroInput.val(), kwhInput.val());

		rotor.addClass('rotate');
		tree1.fadeIn(1200, easingFunction);
		tree4.delay(300).fadeIn(1200, easingFunction);
		tree5.delay(500).fadeIn(1200, easingFunction);
		tree2.delay(600).fadeIn(1200, easingFunction);
		tree3.delay(650).fadeIn(1200, easingFunction);

		if (!isMobile)  {
			step1.delay(1400).fadeOut(duration, easingFunction, function() {
				step2.fadeIn(duration, easingFunction, function() {
					// rotor.removeClass('rotate');
				});
			});
		}
	}

	function resetForm() {
		rotor.removeClass('rotate');
		tree1.fadeOut(600, easingFunction);
		tree2.fadeOut(600, easingFunction);
		tree3.fadeOut(600, easingFunction);
		tree4.fadeOut(600, easingFunction);
		tree5.fadeOut(600, easingFunction);

		step2.fadeOut(duration, easingFunction, function() {
			step1.fadeIn(duration, easingFunction);
			sharesCalculation.show();
			sharesExplanation.hide();
			kwhCalculation.show();
			kwhExplanation.hide();
		});
	}

	form.on('submit', function(e){
		e.preventDefault();
		submitForm();
	});

	kwhSelectBtns.click(function(e) {
		var btn = $(this);
		// console.log('co2SavingsCalculator.js -> init(): btn=', btn);
		$(kwhInput).val(btn.data('value'));
		kwhSelectBtns.removeClass('selected');
		btn.addClass('selected');
	});

	euroInput.val(sharesInput.val() * euroPerShare);

	kwhInput.keydown(function() {
		kwhSelectBtns.removeClass('selected');
	});

	sharesInput.keyup(function() {
		euroInput.val(sharesInput.val() * euroPerShare);
	});

	euroInput.keyup(function() {
		// console.log(euroInput.val(), euroPerShare);
		sharesInput.val(euroInput.val() / euroPerShare);
	});

	function updateValues( button, inputValue ) {
		// console.log('updateValues(%s, %s)', button, inputValue);
		var oldValue = inputValue;
		var newVal;

		if (button.text() === "+") {
			newVal = parseFloat(oldValue) + 1;
		} else {
			// Don't allow decrementing below zero
			if (oldValue > 0) {
				newVal = parseFloat(oldValue) - 1;
			} else {
				newVal = 0;
			}
		}

		newVal = Math.round(newVal);
		sharesInput.val(newVal);
		euroInput.val(newVal*euroPerShare);
	}

	/* prevent contextmenu, opening on long touch */
	stepperBtns.on('contextmenu', function(e) {
		e.preventDefault();
	});

	/* update values on click or short touch */
	stepperBtns.on('click', function(e) {
		var button =  $(this);
		updateValues(button, sharesInput.val());
	});

	/* start interval to update values continuously, on long mouse down and long touch */
	stepperBtns.on('mousedown touchstart', function(e) {
		var button =  $(this);

		intervalId = setInterval(function() {
			// console.log('intervalId', intervalId);
			updateValues(button, sharesInput.val());
		}, 150);
	}).bind('mouseup mouseleave touchend', function(e) {
		clearInterval(intervalId);
	});

	resetBtn.click(function(e) {
		e.preventDefault();
		resetForm();
	});

	sharesCtaExplanation.click(function(e) {
		e.preventDefault();
		sharesExplanation.fadeIn('fast', easingFunction);
	});

	sharesCtaBack.click(function(e) {
		e.preventDefault();
		sharesExplanation.fadeOut('fast', easingFunction);
	});

	kwhCtaExplanation.click(function(e) {
		e.preventDefault();
		kwhExplanation.fadeIn('fast', easingFunction);
	});

	kwhCtaBack.click(function(e) {
		e.preventDefault();
		kwhExplanation.fadeOut('fast', easingFunction);
	});
}

module.exports = {
	init: init
};

